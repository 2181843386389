import { getPaymentOptions } from '../../../../utils/payment/payment';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { CreateActionParams } from '../actions';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { FormContext } from '../../../../utils/context/contextFactory';

export type SetNumberOfParticipants = (numberOfParticipants: number) => void;

export function createSetNumberOfParticipantsAction(
  actionFactoryParams: CreateActionParams,
): SetNumberOfParticipants {
  return async (numberOfParticipants: number) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { context } = actionFactoryParams;
    const {
      calculatePaymentDetails,
      setPaymentOption,
    } = actionFactoryParams.internalActions;
    setState({
      numberOfParticipants,
      status: FormStatus.PROCESSING_PAYMENT_DETAILS,
    });

    calculatePaymentDetails();

    const nextValidOptionId = getNextValidOptionIdIfCurrentPaymentOptionIsDisabled(
      { state, context, numberOfParticipants },
    );
    if (nextValidOptionId) {
      setPaymentOption(nextValidOptionId);
    }
  };
}

const getNextValidOptionIdIfCurrentPaymentOptionIsDisabled = ({
  state,
  context,
  numberOfParticipants,
}: {
  state: FormState;
  context: FormContext;
  numberOfParticipants: number;
}) => {
  const {
    service,
    pricingPlanDetails,
    isPricingPlanInstalled,
    businessInfo,
    selectedPaymentOptionId,
  } = state;
  const { t, settings } = context;

  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const dateAndTimeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale!,
  );
  const paymentOptions = getPaymentOptions({
    servicePayment: service?.payment,
    pricingPlanDetails,
    isPricingPlanInstalled,
    dateAndTimeFormatter,
    numberOfParticipants,
    dateRegionalSettingsLocale,
    t,
    settings,
  });

  const currentSelectedOption = paymentOptions.find(
    (paymentOption) => paymentOption.id === selectedPaymentOptionId,
  );
  if (currentSelectedOption?.disabled) {
    return paymentOptions.find((paymentOption) => !paymentOption.disabled)?.id;
  }
};
